<template>
    <div>
      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-solar-panel"></i> Componentes cadastrados
          </h5>
        </div>
        <div class="w-100 p-3">
          <b-button variant="primary" :to="{ name: 'components-create' }">
            <i class="fas fa-briefcase"></i> Adicionar novo componente
          </b-button>
        </div>
        <div class="w-100 p-3">
          <b-table
          id="tb-panels"
          responsive
          bordered
          :current-page="currentPage"
          :per-page="perPage"
          :busy="load"
          :items="components"
          :fields="columsTableComponents">
            <template #table-busy>
              <div class="text-center text-success my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Carregando...</strong>
              </div>
            </template>
            <template v-slot:cell(actions)="data">
                <div>
                <b-button
                :to="{ name: 'components-edit', params: { id: data.item.id } }"
                v-b-tooltip.hover
                title="Editar Componente"
                size="sm"
                class="mr-1"
                variant="warning">
                    <i class="fas fa-pencil-alt"></i> Editar
                </b-button>
                <b-button
                @click="confirmRemove(data.item.id)"
                title="Excluir painel"
                size="sm"
                class="mr-1"
                variant="danger"
                >
                  <i class="fas fa-trash"></i> Excluir
                </b-button>
                </div>
            </template>
          </b-table>
        </div>

        <div class="w-100 py-3" v-if="totalRows > 15">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
        ></b-pagination>
      </div>
      </b-row>
    </div>
  </template>

<script>
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ComponentsService from '@/Services/ComponentsService';

export default {
  data() {
    return {
      id_remove: null,
      columsTableComponents: ComponentsService.getFieldsTableComponents(),
      load: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 15,
      components: [],
      payload: {
        description: '',
        unit: '',
        price: '',
      },
    };
  },
  mixins: [MessagesMixin, QuestionMixin],
  methods: {
    async getAll() {
      // const filters = { ...this.filters };

      try {
        const { data } = await ComponentsService.getList();
        this.totalRows = data.length;
        this.components = data.map((item) => ({
          id: item.id,
          description: item.description,
          unit: item.unit,
          price: item.price,
        }));
      } catch (error) {
        console.log(error);
      }
      this.load = false;
    },
    confirmRemove(id) {
      this.id_remove = id;
      this.$toast.question(
        'Deseja realmente remover esse componente ?',
        'Atenção',
        this.getQuestionObj(this.deleteComponent, '', 'componente removido'),
      );
    },
    async deleteComponent() {
      try {
        await ComponentsService.delete(this.id_remove);
        this.getAll();
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getAll();
  },
};
</script>
